import React, { ReactNode } from "react"

import * as Swetrix from "swetrix"

import SiteContextProvider from "@context/site-context"

type wrapRootElementProps = {
   element: ReactNode
}

/**
 *
 * Run proxy as a subdirectory (Plausible guide but relevant to Swetrix)
 * https://plausible.io/docs/proxy/guides/cloudflare#step-6-optional-run-proxy-as-a-subdirectory
 */
export const wrapRootElement = ({ element }: wrapRootElementProps) => {
   Swetrix.init(process.env.GATSBY_SWETRIX_PROJECT_ID!, {
      devMode: false,
      apiURL: "https://edbartholomew.com/services/event",
   })
   Swetrix.trackViews()

   return <SiteContextProvider>{element}</SiteContextProvider>
}
